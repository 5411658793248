import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './ForgotPassword.css'

const PasswordReset = () => {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  
  const token = searchParams.get('token');

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setMessage('');

    try {
      const response = await fetch(`${global.baseUrl}users/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to send reset email');
      }

      setMessage('Password reset link has been sent to your email');
      setEmail('');
    } catch (err) {
      setError(err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setMessage('');

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${global.baseUrl}users/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          newPassword,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to reset password');
      }

      setMessage('Password has been successfully reset');
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (err) {
      setError(err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="pwd-reset-container">
      <div className="pwd-reset-card">
        <div className="pwd-reset-header">
          <h1 className="pwd-reset-title">
            {token ? 'Reset Password' : 'Forgot Password'}
          </h1>
        </div>
        
        {message && (
          <div className="pwd-reset-alert pwd-reset-alert-success">
            {message}
          </div>
        )}
        
        {error && (
          <div className="pwd-reset-alert pwd-reset-alert-error">
            {error}
          </div>
        )}

        {!token ? (
          <form onSubmit={handleForgotPassword} className="pwd-reset-form">
            <div className="pwd-reset-form-group">
              <input
                type="email"
                className="pwd-reset-input"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="pwd-reset-submit-btn"
              disabled={loading}
            >
              {loading && <span className="pwd-reset-spinner"></span>}
              {loading ? 'Sending...' : 'Send Reset Link'}
            </button>
            <a href="/login" className="pwd-reset-back-link">
              Back to Login
            </a>
          </form>
        ) : (
          <form onSubmit={handleResetPassword} className="pwd-reset-form">
            <div className="pwd-reset-form-group">
              <input
                type="password"
                className="pwd-reset-input"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                minLength={8}
              />
            </div>
            <div className="pwd-reset-form-group">
              <input
                type="password"
                className="pwd-reset-input"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                minLength={8}
              />
            </div>
            <button
              type="submit"
              className="pwd-reset-submit-btn"
              disabled={loading}
            >
              {loading && <span className="pwd-reset-spinner"></span>}
              {loading ? 'Resetting...' : 'Reset Password'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default PasswordReset;