import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../styles/sidebar.css';
import { useTranslation } from 'react-i18next';
import logo from '../images/logo.png';
import logo1 from '../images/logo1.png';

function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en';
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobileActive, setIsMobileActive] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

  const isAdminSection = location.pathname.startsWith('/admin');

  function navigateToHome() {
    navigate(isAdminSection ? '/admin' : '/');
  }

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleMobileNav = () => {
    setIsMobileActive(!isMobileActive);
  };

  const handleNavigation = (path) => {
    setIsVisible(false);
    setIsMobileActive(false);
    setTimeout(() => {
      navigate(path);
      setIsVisible(true);
    }, 300);
  };

  const handleLogout = () => {
    setShowLogoutConfirm(true);
  };

  const confirmLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    handleNavigation('/');
    setShowLogoutConfirm(false);
  };

  const cancelLogout = () => {
    setShowLogoutConfirm(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobileActive(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isHomePage = [ '/', '/useful/information', '/bank/services', '/aboutUs', '/contactUs', '/rental/services', '/deposit/services'].includes(location.pathname);
  const hideSidebar = [ '/login','/reset-password', '/forgot-password', '/useful/information', '/send-email', '/signUp', '/signup', ].includes(location.pathname);

  if (hideSidebar) {
    return null;
  }

  const renderAdminSidebar = () => (
    <>
      <li>
        <Link onClick={() => handleNavigation('/admin')} className={location.pathname === '/admin/dashboard' ? 'active' : ''}>
          <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
          </svg>
          <span>{t('Dashboard')}</span>
        </Link>
      </li>
      <li>
        <Link onClick={() => handleNavigation('/dashboard')} className={location.pathname === '/' ? 'active' : ''}>
          <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
            <path d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z" />
          </svg>
          <span>{t('switchToUserMode')}</span>
        </Link>
      </li>
      <li>
        <Link onClick={() => handleNavigation('/send-email')} className={location.pathname === '/' ? 'active' : ''}>
          <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
            <path d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z" />
          </svg>
          <span>{t('Send Emails')}</span>
        </Link>
      </li>
    </>
  );

  const renderUserSidebar = () => (
    <>
      <li>
        <Link onClick={() => handleNavigation('/dashboard')} className={location.pathname === '/dashboard' ? 'active' : ''}>
          <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
          </svg>
          <span>{t('Dashboard')}</span>
        </Link>
      </li>
      <li>
        <Link onClick={() => handleNavigation('/transactions')} className={location.pathname === '/transactions' ? 'active' : ''}>
          <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
            <path d="M3 3h18v2H3V3zm2 14h10v-2H5v2zm10-7H5v2h10v-2zm-5-7v2h10V3H10zm2 18h10v-2H12v2z" />
          </svg>
          <span>{t('transactions')}</span>
        </Link>
      </li>
      <li>
        <Link onClick={() => handleNavigation('/transfer')} className={location.pathname === '/transfer' ? 'active' : ''}>
          <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
            <path d="M12 2l-5.5 9h11L12 2zm0 20l-5.5-9h11L12 22z" />
          </svg>
          <span>{t('transfer')}</span>
        </Link>
      </li>
      <li>
        <Link onClick={() => handleNavigation('/deposit')} className={location.pathname === '/deposit' ? 'active' : ''}>
          <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
            <path d="M4 21v-2h16v2H4zm6-6v2h4v-2h5.33L12 5.67 4.67 15H10z" />
          </svg>
          <span>{t('deposit')}</span>
        </Link>
      </li>
      <li>
        <Link onClick={() => handleNavigation('/settings')} className={location.pathname === '/settings' ? 'active' : ''}>
          <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
            <path d="M19.14 12.94c.04-.31.06-.63.06-.94s-.02-.63-.06-.94l2.03-1.6c.18-.14.22-.39.12-.59l-1.92-3.32c-.1-.18-.33-.25-.52-.19l-2.39.96c-.5-.38-1.05-.7-1.66-.93L14.5 2.9c-.03-.19-.19-.34-.38-.34h-4.24c-.19 0-.35.15-.38.34L8.17 6.19c-.61.23-1.16.54-1.66.93l-2.39-.96c-.19-.08-.42.01-.52.19l-1.92 3.32c-.1.18-.06.44.12.59l2.03 1.6c-.04.31-.06.63-.06.94s.02.63.06.94l-2.03 1.6c-.18.14-.22.39-.12.59l1.92 3.32c.1.18.33.25.52.19l2.39-.96c.5.38 1.05.7 1.66.93l.63 2.68c.03.19.19.34.38.34h4.24c.19 0 .35-.15.38-.34l.63-2.68c.61-.23 1.16-.54 1.66-.93l2.39.96c.19.08.42-.01.52-.19l1.92-3.32c.1-.18.06-.44-.12-.59l-2.03-1.6zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z" />
          </svg>
          <span>{t('settings')}</span>
        </Link>
      </li>
      <li>
        <Link onClick={handleLogout} className="logout-link">
          <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
            <path d="M10 17v-2h8v-2h-8V9l-4 4 4 4zM3 3h8v2H5v14h6v2H3V3z" />
          </svg>
          <span>Log Out</span>
        </Link>
      </li>
      
    </>
  );

  const renderLanguageButtons = () => (
    <>
      <li>
        <Link onClick={() => changeLanguage('zh')}>
          <span>{t('Change Language 中文')}</span>
        </Link>
      </li>
      <li>
        <Link onClick={() => changeLanguage('en')}>
          <span>{t('Change Language to English')}</span>
        </Link>
      </li>
    </>
  );

  return (
    <>
      <div className={`sidebar-container ${isVisible ? 'visible' : 'hidden'}`}>
   
        <div className="mobile-nav">
          <img className='sideBarLogoM' onClick={navigateToHome} src={logo1} alt="" />
          <button className={`hamburger-icon ${isMobileActive ? 'active' : ''}`} onClick={toggleMobileNav}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <ul className={`mobile-dropdown ${isMobileActive ? 'active' : ''}`}>
          {isHomePage ? (
            <>
              <li>
                <Link onClick={() => handleNavigation('/login')}>
                  <span>{t('onlineBanking')}</span>
                </Link>
              </li>
              <li>
                <Link onClick={() => handleNavigation('/contactUs')}>
                  <span>{t('contactUs')}</span>
                </Link>
              </li>
              <li>
                <Link onClick={() => handleNavigation('/login')}>
                  <span>{t('login')}</span>
                </Link>
              </li>
              <li>
                <Link onClick={() => handleNavigation('/signUp')}>
                  <span>{t('signUp')}</span>
                </Link>
              </li>
              {renderLanguageButtons()}
            </>
          ) : (
            <>
              {isAdminSection ? renderAdminSidebar() : renderUserSidebar()}
              {renderLanguageButtons()}
            </>
          )}
        </ul>
        {!isHomePage && (
          <nav className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
            <div className="sidebar-header">
              {!isCollapsed && <img onClick={navigateToHome} className='sideBarLogo' src={logo} alt="" />}
              {/* <button className="collapse-btn" onClick={toggleCollapse}>
                {isCollapsed ? '▶' : '◀'}
              </button> */}
            </div>
            <ul className="sidebar-menu">
              {isAdminSection ? renderAdminSidebar() : renderUserSidebar()}
              {renderLanguageButtons()}
              {/* <li>
                <Link onClick={handleLogout} className={location.pathname === '/logout' ? 'active' : ''}>
                  <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
                    <path d="M10 17v-2h8v-2h-8V9l-4 4 4 4zM3 3h8v2H5v14h6v2H3V3z" />
                  </svg>
                  <span>{t('logout')}</span>
                </Link>
              </li> */}
            </ul>
          </nav>
        )}
      </div>
      {showLogoutConfirm && (
        <div  className="modal-overlay">
          <div id='modal-logout' className="side-modal-content">
            <h2>Are you sure you want to logout?</h2>
            <p>This action will end your current session.</p>
            <div className="modal-actions">
              <button onClick={cancelLogout} className="cancel-btn">Cancel</button>
              <button onClick={confirmLogout} className="confirm-btn">Yes, Logout</button>
            </div>
          </div>
        </div>
      )}
      {/* {showLogoutConfirm && (
        <div className="modal-overlay">
          <div id='modal-logout' className="side-modal-content">
            <h2>{t('logoutConfirmation')}</h2>
            <p>{t('logoutMessage')}</p>
            <div className="modal-actions">
              <button onClick={cancelLogout} className="cancel-btn">{t('cancel')}</button>
              <button onClick={confirmLogout} className="confirm-btn">{t('confirmLogout')}</button>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}

export default Sidebar;